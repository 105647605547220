import React, { useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import Scribble from '../../Utils/Scribble.js';
import SceneComponent from './SceneComponent';
import { ModelList } from "../../Utils/ModelsList.js"
import LayerControl from './LayerControl'; // Import LayerControl component
import Measurements from '../../clickTools/Measurements.js';
import Info from '../../clickTools/Info.js';
import ColorChange from '../../clickTools/ColorChange.js';
import ObjectDetails from '../../clickTools/ObjectDetails.js';
import ImageViewer from './ImageViewer.js';
import Statistics from './Statistics.js';
import ScanViewer from './ScanViewer.js';
import { useProgress } from '@react-three/drei';
import { handleLogout } from '../../firebase/firebaseConfig.js';

const Presentation = () => {
  const { progress } = useProgress()

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isScribbleMode, setIsScribbleMode] = useState(false);
  const [selectedColor, setSelectedColor] = useState('black');
  const [previousSlideWasScene, setPreviousSlideWasScene] = useState(false);
  const [showDescription, setShowDescription] = useState(true); // Track description visibility
  const [showStatistics, setShowStatistics] = useState(false);
  const [statisticsType, setStatisticsType] = useState('none')
  
  // Automatically close sidebar on medium screens and below
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) { // Adjust the breakpoint as needed
        setIsSidebarOpen(false);
      }
    };

    // Initial check and add event listener
    handleResize();
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  useEffect(()=>{
    console.log('presentation refreshed')
  },
    [])


  // Define the slides, with some containing scene-specific content
  const slides = [
    { type: 'image', heading: '3DMA', content: './presentation/giant1.jpg' },
    { type: 'image', heading: '3DMA', content: './presentation/3DMA.jpg' },
    { type: 'image', heading: '3DMA', content: './presentation/3DMA1.jpg' },
    { type: 'image', heading: '3DMA', content: './presentation/3DMA3.jpg' },
    {
      type: 'scene',
      heading: 'Old Workings',
      content: {
        visibleObjects: ['UG_Workings'],
        cameraPosition: [199291.0419564719, 1274.8597889083126,-8004173.893210932],
        lookAt: [198168.30897941053, 952.1289562720644,-8004409.822362194],
        description:`• </strong> All UG Workings: Historic <strong> 
                   `
      }
    },
    {
      type: 'scene',
      heading: 'Topo',
      content: {
        visibleObjects: ['Topo','UG_Workings'],
        cameraPosition: [199799, 2254,-8003913],
        lookAt: [198631, 1216,-8004233],
        description:`Digitized UG Old workings merged with Topo`
      }
    },
    {
      type: 'scene',
      heading: 'Makorokoza',
      content: {
        visibleObjects: ['Topo','Makorokoza','UG_Workings'],
        cameraPosition: [198700, 1126,-8004252],
        lookAt: [198631, 1126,-8004260],
        description:`Artisanals take advantage of no planning `
      }
    },
    {
      type: 'scene',
      heading: 'Drilling',
      content: {
        visibleObjects: ['HIST_DD','HIST_RC','RES_RC','UG_Workings','Drone'],
        cameraPosition: [198180, 1139,-8005002],
        lookAt: [198147, 1014,-8004108],
        description:`Surface drilling proves lots of gold`
      }
    },
    {
      type: 'scene',
      heading: 'Orebody',
      content: {
        visibleObjects: ['HIST_DD','HIST_RC','RES_RC','Orebody_HG'],
        cameraPosition: [198223, 1229,-8004975],
        lookAt: [198178, 1149,-8004761],
        description:`• <strong>Geology is now modelled!</strong> `
      }
    },
    {
      type: 'scene',
      heading: 'Overview',
      content: {
        visibleObjects: ['UG_Workings','Makorokoza','Orebody_HG'],
        cameraPosition: [198694, 1398,-8005275],
        lookAt: [198428, 1212,-8004894],
        description:`• <strong>Production Statistics </strong> `
      }
    },
    {
      type: 'scene',
      heading: 'Drone',
      content: {
        visibleObjects: ['Drone','UG_Workings','Makorokoza','Orebody_HG'],
        cameraPosition: [198547, 1530,-8004261],
        lookAt: [198512, 1495,-8004261],
        description:`Surface model capture by Drone`
      }
    },
    {
      type: '360',
      heading: 'Reinforcement 360',
      content: {
        imageUrl: './360/Support.jpg',
        description:`HANGING WALL SUPPORT - 360`
      }
    },
    {
      type: '360',
      heading: 'Markings 360',
      content: {
        imageUrl: './360/Markings.jpg',
        description:`HAULAGE MARKINGS - 360`
      }
    },
    {
      type: '360',
      heading: 'Makorokoza 360',
      content: {
        imageUrl: './360/G3.jpg',
        description:`MAKORKOZA SHAFTS - 360`
      }
    },
    {
      type: '360',
      heading: 'DD Drilling 360',
      content: {
        imageUrl: './360/DDrilling.jpg',
        description:`DIAMOND DRILLING - 360`
      }
    },
    {
      type: '360',
      heading: 'Box 360',
      content: {
        imageUrl: './360/Box.jpg',
        description:`COCOPAN LOADING ON BOX - 360`
      }
    },
    {
      type: '360',
      heading: 'BlastHole 360',
      content: {
        imageUrl: './360/Blast_Drilling.jpg',
        description:`BLASTHOLE DRILLING - 360`
      }
    },
    {
      type: '360',
      heading: 'Battery 360',
      content: {
        imageUrl: './360/Battery.jpg',
        description:`BATTERY ROOM - 360`
      }
    },
    {
      type: 'scene',
      heading: 'DRIVE SCAN',
      content: {
        visibleObjects: ['DriveScan'],
        cameraPosition: [-7, -1.4,5.8],
        lookAt: [-7.5, -1.5,-1],
        description:`DRIVE SCAN`
      }
    },
    {
      type: 'scene',
      heading: 'STOPE SCAN',
      content: {
        visibleObjects: ['StopeScan'],
        cameraPosition: [9, -159.8,-135],
        lookAt: [27, -161,-136],
        description:`STOPE SCAN`
      }
    },
    { type: 'image', heading: '3DMA', content: './presentation/3DMA2.jpg' },
    { type: 'image', heading: 'END', content: './presentation/3DMA4.jpg' },
  ];

  const handleSlideClick = (index) => {
    setPreviousSlideWasScene(slides[currentSlide].type === 'scene');
    setCurrentSlide(index);
    setIsScribbleMode(false); // Disable scribble mode on page change
    
    setShowDescription(true)
    


    const newVisibility = { ...layerVisibility };
    
    // Reset all layers to invisible
    Object.keys(newVisibility).forEach((key) => {
      newVisibility[key].visible = false;
    });

    // Enable only the layers specified in the slide's visibleObjects
    const visibleObjects = slides[index]?.content?.visibleObjects || [];
    visibleObjects.forEach((name) => {
      if (newVisibility[name]) {
        newVisibility[name].visible = true;
      }
    });

    setLayerVisibility(newVisibility);
  };

  const handleToggleScribbleMode = () => {
    setIsScribbleMode(!isScribbleMode);
  };

  const renderSlideContent = () => {
    const slide = slides[currentSlide];
    switch (slide.type) {
      case 'image':
        return <img src={slide.content} alt="Slide" className="max-w-full max-h-full mx-auto overflow-hidden" />;

      case 'video':
        return (
          <video controls className="max-w-full max-h-full mx-auto">
            <source src={slide.content} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );

      case 'youtube':
        return (
          <iframe
            width="100%"
            height="100%"
            src={slide.content}
            title="YouTube video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="max-w-full max-h-full mx-auto"
          ></iframe>
        );

      case 'scene':
        return null; // We render the Canvas separately for scene slides

      case '360':
        return null;

      default:
        return null;
    }
  };


  //////////LAYER VISIBILITY////////////////
  
  const [layerVisibility, setLayerVisibility] = useState({}); // Manage visibility of each layer
  const [showLayerControl, setShowLayerControl] = useState(false); // Toggle for Layer Control visibility
  
  useEffect(() => {
    const initialVisibility = {};
    ModelList.forEach((group) => {
      group.models.forEach((model) => {
        initialVisibility[model.name] = { visible: false, url: model.url };
      });
    });
    setLayerVisibility(initialVisibility);
  }, []);

   // Toggle visibility of layers from LayerControl
  const handleToggleVisibility = (modelName) => {
    setLayerVisibility((prevState) => ({
      ...prevState,
      [modelName]: {
        ...prevState[modelName],
        visible: !prevState[modelName].visible,
      }
    }));
  };

  const handleStatisticsTypeChange = (event) => {
    
    setStatisticsType(event.target.value);
    setShowStatistics(event.target.value !== "none")
    
  };

  
  

  return (
    <div className="flex h-screen relative">
      {/* Sidebar */}
      <div className={`transition-all duration-300 z-50 bg-blue-800 text-white p-1 h-full flex flex-col ${isSidebarOpen ? 'w-1/4 max-w-xs' : 'w-12 lg:w-18 md:w-16'}`}>
        <div className="relative hidden md:flex justify-center mb-4">
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="bg-blue-600 text-white p-2 rounded-full shadow-md hover:bg-blue-700 focus:outline-none"
          >
            {isSidebarOpen ? '←' : '→'}
          </button>
        </div>
        
        {isSidebarOpen ?
        (<div className="mb-4">
          <label htmlFor="statisticsType" className="block text-white font-semibold mb-2">
            Select Statistics Type:
          </label>
          <select
            id="statisticsType"
            value={statisticsType}
            onChange={handleStatisticsTypeChange}
            className="w-full p-2 rounded bg-white text-black"
          >
            <option value="none">None</option>
            <option value="hoisting">Hoisting</option>
            <option value="development">Development</option>
            {/* Add more options if needed */}
          </select>
        </div>):null
        }


        {isSidebarOpen ?( 
          showStatistics?(
            <Statistics statisticsType={statisticsType} />
          ):
          (
            <div  className=' h-screen overflow-y-auto p-5' >
              <h2 className="text-sm md:text-xl lg:text-2xl font-bold mb-4 text-center header">ZIMBABWE MINE</h2>
              <ul >
                {slides.map((slide, index) => (
                  <li
                    key={index}
                    className={` flex items-center p-3 cursor-pointer ${index === currentSlide ? 'bg-blue-700' : 'bg-blue-600'} hover:bg-blue-700 transition-all rounded-lg mb-2`}
                    onClick={() => handleSlideClick(index)}
                  >
                    <div className={`w-10 h-10  flex items-center justify-center rounded-full shadow text-lg font-bold ${index === currentSlide ? 'bg-blue-400' : 'bg-blue-300'}`}>
                      {index + 1}
                    </div>
                    <span className="text-md font-bold ml-4">{slide.heading}</span>
                  </li>
                ))}
              </ul>
            </div>
          )
        ) : (
          <ul className="flex flex-col items-center space-y-4 mt-12 overflow-y-auto h-screen" >
            {slides.map((_, index) => (
              <li
                key={index}
                className={`w-6 h-6 md:w-10 d:h-10 flex items-center justify-center cursor-pointer rounded-full shadow text-lg font-bold ${index === currentSlide ? 'bg-blue-400 text-white' : 'bg-blue-300 text-blue-900'}`}
                onClick={() => handleSlideClick(index)}
              >
                {index + 1}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Main Content */}
      <div className="flex-grow flex flex-col transition-all duration-300  overflow-hidden">
        {(slides[currentSlide].type === 'scene' || slides[currentSlide].type === '360') && progress < 100 && (
          <div className="fixed inset-0 z-40 flex flex-col items-center justify-center bg-gray-900 bg-opacity-90">
            <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
            <h1 className="mt-4 text-white text-xl font-semibold">Loading ...</h1>
            <p className="text-white text-lg">{progress.toFixed(0)}%</p>
          </div>
        )}
        <div className="flex-grow flex items-center justify-center bg-gray-400">
          {renderSlideContent()}
        </div>
        {/* Description Box */}
        {showDescription && slides[currentSlide].content.description && (
          <div className="absolute bottom-16 right-4   bg-gray-800 bg-opacity-70 text-white rounded-lg px-4 py-2 flex items-center space-x-2 z-50">
            <p className='self-center' dangerouslySetInnerHTML={{ __html: slides[currentSlide].content.description }}></p>
            <button
              onClick={() => setShowDescription(false)}
              className="text-gray-500 hover:text-gray-700 font-bold text-sm w-fit"
            >
              &times;
            </button>
          </div>
        )}

        


        {slides[currentSlide].type === 'scene' && (
          <>

            {/* Toggle Button for Layer Control */}
             <button
              onClick={() => setShowLayerControl(!showLayerControl)}
              className = {`absolute top-16 right-4  ${showLayerControl?'bg-blue-500':'bg-gray-500'} text-white w-8 h-8 flex items-center justify-center rounded-full shadow-lg hover:bg-blue-600 z-50`}
              >
              🗂️
            </button>
           

            {/* Layer Control Panel */}
            {showLayerControl && (
              <LayerControl
                modelList={ModelList}
                layerVisibility={layerVisibility}
                onToggleVisibility={handleToggleVisibility}
              />
            )}

       
            <Canvas className="absolute inset-0" flat shadows gl={{ antialias: true }} camera={{ position: [0, 7000, 0], near: 0.5, far: 10000, fov: 35 }}>
              <SceneComponent
                currentSlide = {currentSlide}
                layerVisibility={layerVisibility} 
                cameraPosition={slides[currentSlide].content.cameraPosition}
                lookAt={slides[currentSlide].content.lookAt}
                animateCamera={previousSlideWasScene}
              />
            </Canvas>
            
            <Measurements/>
            <ColorChange/>
            <ObjectDetails/>
            <Info/>
          </>



        )}

        {slides[currentSlide].type === '360' && (
          <>
            
              <ImageViewer imageUrl={slides[currentSlide].content.imageUrl}/>
            
          </>
        )}

        {slides[currentSlide].type === 'Scan' && (
          <>
            
              <ScanViewer url={slides[currentSlide].content.url} name={slides[currentSlide].content.name} />
            
          </>
        )}  

        {/* Sign Out Button */}
        <button
          onClick={handleLogout}
          className="absolute top-4 right-12 bg-gray-500 text-white w-8 h-8 flex items-center justify-center rounded-full shadow-lg hover:bg-gray-600 z-50"
        >
          🔓
        </button>


        {/* Scribble Toggle Button */}
        <button
          onClick={handleToggleScribbleMode}
          className="absolute top-4 right-4 bg-gray-500 text-white w-8 h-8 flex items-center justify-center rounded-full shadow-lg hover:bg-gray-600 z-50"
        >
          ✏️
        </button>

        {/* Scribble Component */}
        <Scribble
          isScribbleMode={isScribbleMode}
          selectedColor={selectedColor}
          onColorChange={setSelectedColor}
        />

        

        {/* Footer Navigation */}
        <div className=" bg-blue-200 text-blue-900 flex justify-between items-center px-4">
          <button
            onClick={() => handleSlideClick((currentSlide - 1 + slides.length) % slides.length)}
            className="bg-blue-600 text-white w-fit px-3 rounded shadow-md hover:bg-blue-700"
          >
            Previous
          </button>
          <span className="text-center text-sm md:text-lg w-fit">
            <div className="w-fit flex items-center p-1 justify-center rounded-full shadow bg-gray-100 font-bold">
              {currentSlide + 1} / {slides.length}
            </div>
          </span>
          <button
            onClick={() => handleSlideClick((currentSlide + 1) % slides.length)}
            className="bg-blue-600 text-white w-fit px-3 rounded shadow-md hover:bg-blue-700"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Presentation;
