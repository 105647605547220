import React, { useState, useEffect } from 'react';
import './index.css';
import Presentation from './components/presentation/Presentation.js';
import Authentication from './Auth.js';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from './firebase/firebaseConfig.js';

export default function App() {
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const currentProject= "ZIMMINE-001"; // Set your current project here
  // const [deviceId, setDeviceId] = useState(localStorage.getItem('deviceId')); // Retrieve device ID from local storage

  // useEffect(() => {
  //   // Check authentication state
  //   const unsubscribe = onAuthStateChanged(auth, async (user) => {
  //     console.log(user)
  //     if (user) {
  //       try {
  //         // Get user document from Firestore
  //         const userRef = doc(db, `projects/${currentProject}/users`, user.email);
  //         const userDoc = await getDoc(userRef);
  //         console.log(userDoc)
  //         if (userDoc.exists()) {
  //           const devices = userDoc.data().devices;
  //           console.log(deviceId)
  //           console.log(devices)
  //           // Check if the device is registered and activated for the current project
  //           const deviceEntry = Object.values(devices).find(device => device.deviceId === deviceId);
  //           console.log(deviceEntry)
  //           if (deviceEntry && deviceEntry.activated) {
  //             setIsAuthenticated(true);
  //           } else {
  //             setIsAuthenticated(false);
  //           }
  //         } else {
  //           setIsAuthenticated(false);
  //         }
  //       } catch (error) {
  //         console.error('Error fetching user project:', error);
  //         setIsAuthenticated(false);
  //       }
  //     } else {
  //       setIsAuthenticated(false);
  //     }
  //   });

  //   return () => unsubscribe();
  // }, [currentProject, deviceId]);

  // useEffect(() => {
  //   console.log(isAuthenticated)
  // }, [isAuthenticated]);

  // const handleLogout = () => {
  //   signOut(auth);
  //   setIsAuthenticated(false);
  // };

  return (
    // isAuthenticated ? <Presentation/>:
    <Authentication project={currentProject} />   
  )

  

 
}
